/* Product Details Section */
.cartWrapperActionSection {
    padding-inline: 1rem;
    padding-block: 0.43rem;
    border-width: 0.4px;
    width: 70%;
    align-items: center;
    border-radius: 3px;
    border-color: #707070;
    border-style: solid;
    cursor: pointer;

}

.textCommonStyledDetails {
    margin-inline: 0;
    font-family: 'Poppins-Regular';
    font-size: 18;
}

/* Product Action Section */

.quantityWrapperActionSection {
    padding-inline: 1rem;
    padding-block: 0.4rem;
    width: 70%;
    align-items: 'center';
    border-radius: 3px;
    border: 0.4px solid #707070;
    display: flex;
    justify-content: space-between;
}

.cartWrapper {
    display: flexbox;
    border-radius: 1px;

    align-items: center;
    flex: 1;
    border: 0.4px solid #00000032;
}

.quantityIcon {
    justify-content: 'center';
    align-items: 'center';
    /* padding: 6 */
    cursor: pointer;
}

.product-details-brochure {
    display: flex;
    justify-content: center;
    flex: 1;
}

.product-details-brochure-image {
    height: 200px;
    width: auto
}

.product-details-container {
    display: flexbox;

}

.product-section-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 4rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem
}

.product-section-info-text {
    font-family: 'Poppins-Medium';
    text-decoration-line: underline;
}

.product-details-action-section {
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    display: flex;
    
}

@media only screen and (min-width:992px) {
    .product-details-section {
        margin-left: 5rem;
    }

    .textCommonStyledDetails {
        font-size: 30px;
    }

    .product-details-brochure {
        justify-content: flex-start;
        margin-left: 1rem;
    }

    .product-details-brochure-image {
        height: auto;
        width: 500px;
    }

    .product-details-container {
        display: flex;
    }

    .product-section-info {
        justify-content: center;
        margin-top: 0rem;
        margin-left: 5rem;
        margin-bottom: 0rem;
        margin-right: 0rem
    }

    .product-section-info-text {
        font-size: 24px;
    }
    .product-details-action-section {
        margin-left: 6rem;
    }
}